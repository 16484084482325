import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext.js";
import { SettingsProvider } from "./contexts/SettingsContext";
import { CssBaseline } from "@mui/material";

import routes from "./routes";

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <MatxTheme>
        <CssBaseline />
        <AuthProvider>{content}</AuthProvider>
      </MatxTheme>
    </SettingsProvider>
  );
};

export default App;
