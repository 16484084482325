import { TableCell } from "@mui/material";

const TableCellAlerted = ({ tagobject, children, rowspan, colspan }) => {
  // console.log(tagobject);
 
  const status =
    tagobject.value === 0
      ? "parado"
      : tagobject.value > tagobject.alarma_h_h ||
        tagobject.value < tagobject.alarma_l_l
      ? "danger"
      : tagobject.value > tagobject.alarma_h ||
        tagobject.value < tagobject.alarma_l
      ? "warning"
      : "normal";

  return (
    <TableCell
      sx={[
        status === "danger" && { backgroundColor: "#ac3e31", color: "#fff"},
        status === "warning" && { backgroundColor: "#dbae58" },
      ]}
      rowSpan={rowspan}
      colSpan={colspan}
    >
      {children}
    </TableCell>
  );
};

export default TableCellAlerted;
