import Loadable from "app/components/Loadable";
import { lazy } from "react";

const ConcentratorProduction = Loadable(
  lazy(() => import("./ConcentratorProduction"))
);
const FilteringProduction = Loadable(
  lazy(() => import("./FilteringProduction"))
);
const TailingProduction = Loadable(lazy(() => import("./TailingProduction")));

const productionRoutes = [
  {
    path: "/production/concentrator",
    element: <ConcentratorProduction />,
    auth: [1, 2, 3, 4, 11, 12],
  },
  {
    path: "/production/filtering",
    element: <FilteringProduction />,
    auth: [1, 2, 3, 4, 13],
  },
  {
    path: "/production/tailing",
    element: <TailingProduction />,
    auth: [1, 2, 3, 4, 14],
  },
];

export default productionRoutes;
