import React from "react";
import { RefreshBox } from "./styledComponents";

const RefreshTime = ({ time }) => {
  return (
    <RefreshBox>
      Hora de refresco: <br />
      {time}
    </RefreshBox>
  );
};

export default RefreshTime;
