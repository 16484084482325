import { themeShadows } from "./themeColors";

export const components = {
  MuiTable: {
    styleOverrides: {
      root: {
        tableLayout: "fixed",
      },
    },
  },

  
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: "13px",
        // padding: '12px 0px',
      },
      root: {
        fontSize: "12px",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        padding: "10px 0px",
        textAlign: "center",
        "@media (min-width:600px)": {
          fontSize: "13px",
        },
      },
    },
  },
  MUIDataTableSelectCell: {
    styleOverrides: {
      root: {
        paddingLeft: 12,
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        textTransform: "none",
        fontWeight: "400",
      },
      contained: {
        boxShadow: themeShadows[8],
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        boxSizing: "border-box",
      },
      html: {
        MozOsxFontSmoothing: "grayscale",
        WebkitFontSmoothing: "antialiased",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
      },
      a: {
        textDecoration: "none",
        color: "inherit",
      },
      "#root": {
        height: "100%",
      },
      "#nprogress .bar": {
        zIndex: "2000 !important",
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: themeShadows[12],
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "11px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: "0.875rem",
      },
    },
  },
  MuiExpansionPanel: {
    styleOverrides: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        boxShadow:
          "0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%) !important",
      },
    },
  },
  MuiMasonry: {
    styleOverrides: {

//// to do  
//// eliminar el margin en sx md  actualmente en calc - 8px
//// set width to 100 ///// actualmente en width - 8px

      root: {
        // "@media (max-width:600px)": {
        //   margin: "10px 0px!important;",
        // },
        width:'auto'
      },
    },
  },


  
};
