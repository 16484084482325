import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { CardHeader, DataTable } from "app/components/styledComponents";

const CardWrappertwo = ({
  children,
  titlefirstchild,
  titlesecondchild,
  queryTime,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Card
      elevation={2}
      sx={{ marginTop: "10px", marginBottom: "10px", maxWidth: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0rem 0.5rem",
        }}
      >
        {titlefirstchild && <CardHeader>{titlefirstchild}</CardHeader>}

        {isMobile && (
          <Typography
            variant="caption"
            // style={{ padding: "0.5rem", float: "right" }}
          >
            {queryTime ?? ""}
          </Typography>
        )}
      </Box>
      <DataTable>{children[0]}</DataTable>
      <CardHeader>{titlesecondchild}</CardHeader>
      <DataTable>{children[1]}</DataTable>
    </Card>
  );
};

export default CardWrappertwo;
