import AuthGuard from "app/auth/AuthGuard";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import adminRoutes from "./views/admin/adminRoutes";
import plantsRoutes from "./views/plants/PlantsRoutes";
import productionRoutes from "./views/production/ProductionRoutes";
import homeRoutes from "./views/home/homeRoutes";
import maintenanceRoutes from "./views/maintenance/maintenanceRoutes";

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...homeRoutes,
      ...plantsRoutes,
      ...adminRoutes,
      ...productionRoutes,
      ...maintenanceRoutes
    ],
  },
  ...sessionRoutes,
  { path: "/", element: <Navigate to="/home" /> },
  { path: "*", element: <NotFound /> }, 
];

export default routes
