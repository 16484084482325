import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";

const DateSelector = ({pickerDate, onDateChange}) => {
    const formatedTimePickerDate = format(pickerDate, "yyyy-MM-dd");

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      label="fecha de reporte"
      disableFuture={true}
      format="yyyy-MM-dd"
      defaultValue={parseISO(formatedTimePickerDate)}
      onChange={(newDate) => onDateChange(newDate)}
      closeOnSelect={true}
    />
  </LocalizationProvider>
  )
}

export default DateSelector