import { useLayoutEffect, useRef, useState, useCallback } from "react";  
import {  
  Root,  
  Label,  
  p50,  
  Tooltip,  
  Legend,  
  GridLayout, 
  addLicense 
} from "@amcharts/amcharts5";  
import {  
  DateAxis,  
  AxisRendererX,  
  AxisRendererY,  
  ValueAxis,  
  SmoothedXLineSeries,  
  XYCursor,  
} from "@amcharts/amcharts5/xy";  

import {  
  StockChart,  
  StockPanel,  
  StockToolbar,  
  PeriodSelector,  
} from "@amcharts/amcharts5/stock";  




import { ContentChart } from "../styledComponents";  

import "./graphstyle.css";  
import { format } from "date-fns";  
import { ChartAverage } from "..";

const AMGraph = ({ data, updatedata, settings }) => {  
  const [periodselected, setPeriodselected] = useState(null);  
  const [averageValues, setAverageValues] = useState({  
    start: null,  
    end: null,  
    serieAverage: [],  
  });  
  const tagListParameterLength = settings.axes.length;  
  const graphHeight =  
    tagListParameterLength < 2 ? 420 : tagListParameterLength * 210;  
  const chartRef = useRef(null);  


  //convierte el tiempo de string a valores numericos 
  const processData = useCallback((data) => {  
    return data.map((item) => ({  
      ...item,  
      time: parseInt(item.time),  
    }));  
  }, []);  

  useLayoutEffect(() => {  
    if (!data.payload) return;  

    const processedData = processData(data.payload); 

    addLicense("AM5S-2875-7089-6184-3413");
    let root = Root.new(settings.chartid);  
    

    root.dateFormatter.setAll({  
      dateFormat: "HH:mm:ss",  
      dateFields: ["valueX"],  
    });  

    root.numberFormatter.setAll({  
      numberFormat: "#.",  
      numericFields: ["valueY"],  
    });  

    let chart = root.container.children.push(  
      StockChart.new(root, {  
        paddingLeft: 0,  
        paddingRight: 0,  
        paddingBottom: 0,  
      })  
    );  

    let mainPanel = chart.panels.push(  
      StockPanel.new(root, {  
        panX: false,  
        panY: false,  
        wheelX: "none",  
        wheelY: "none",  
      })  
    );  

    mainPanel.topAxesContainer.children.push(  
      Label.new(root, {  
        text: settings.title,  
        fontSize: 16,  
        fontWeight: "400",  
        x: p50,  
        centerX: p50,  
        marginTop: 1,  
      })  
    );  

    mainPanel.leftAxesContainer.set("layout", root.verticalLayout);  

    const xAxis = mainPanel.xAxes.push(  
      DateAxis.new(root, {  
        baseInterval: { timeUnit: "second", count: 10 },  
        paddingLeft: 20,  
        marginTop: 10,  
        marginBottom: 10,  
        renderer: AxisRendererX.new(root, {  
          minGridDistance: 60,  
          minorGridEnabled: true,  
        }),  
      })  
    );  

    xAxis.get("renderer").labels.template.setAll({  
      fontSize: "13px",  
      fontWeight: "500",  
    });  

    settings.axes.forEach((axe) => {  
      const yRender = AxisRendererY.new(root, {  
        inside: true,  
        opposite: false,  
        minGridDistance: 35,  
        minorGridEnabled: true,  
      });  

      const yAxis = mainPanel.yAxes.push(  
        ValueAxis.new(root, {  
          renderer: yRender,  
          marginTop: 10,  
          marginBottom: 10,  
          paddingTop: 15,  
          paddingBottom: 15,  
        })  
      );  

      yAxis.axisHeader.children.push(  
        Label.new(root, {  
          text: axe.title,  
          fontWeight: 600,  
          fontSize: "0.8rem",  
          x: p50,  
          centerX: p50,  
          marginTop: 10,  
          marginBottom: 10,  
        })  
      );  

      yAxis  
        .get("renderer")  
        .labels.template.setAll({ fontSize: "13px", fontWeight: "500" });  

      axe.series.forEach((chartserie) => {
        
        const serieData = processedData.filter(  
          (row) => row.measurement_column === chartserie.tagName  
        );  
        const serie = mainPanel.series.push(  
          SmoothedXLineSeries.new(root, {  
            name: chartserie.description,  
            xAxis: xAxis,  
            yAxis: yAxis,  
            valueYField: "value",  
            valueXField: "time",  
            userData: { tag: chartserie.tagName, unit: chartserie.unit },  
            tooltip: Tooltip.new(root, {  
              pointerOrientation: "horizontal",  
              labelText:  
                "[bold]{name}[/]\n{valueX}\n[bold]{valueY} {userData.unit}",  
            }),  
            legendLabelText: "[bold]{name}",  
          })  
        );  

        serie.data.setAll(serieData);  
      });  
    });  

    mainPanel.series.values.forEach((serie) => {  
      serie.events.on("boundschanged", ({target}) => {  
        const tagName = target.get("userData").tag;  

        setAverageValues(({ serieAverage, ...rest }) => {  
          const newSeriesAverage = serieAverage.map((pit) => {  
            if (pit.tagName === tagName) {  
              return { ...pit, isVisible: serie.isVisible() };  
            }  
            return pit;  
          });  
          return { ...rest, serieAverage: newSeriesAverage };  
        });  

        const yAxis = serie.get("yAxis");  
        const seriesHidden = yAxis.series.every((serie) => serie.isHidden());  

        if (seriesHidden) {  
          yAxis.axisHeader.hide();  
          yAxis.hide();  
          yAxis.gridContainer.hide();  
        } else {  
          yAxis.axisHeader.show();  
          yAxis.show(100);  
          yAxis.gridContainer.show(100);  
        }  
      });  
    });  

    const setSerieAverage = () => {  
      const newAverageItemsArray = [];  
      const start = format(xAxis.getPrivate("selectionMinFinal"), "HH:mm aaa");  
      const end = format(xAxis.getPrivate("selectionMaxFinal"), "HH:mm aaa");  

      xAxis.series.forEach((serie) => {  
        const tagName = serie.get("userData").tag;  
        const description = serie.get("name");  

        const startPoint =  
          xAxis.getSeriesItem(serie, xAxis.get("start")) || serie.dataItems[0];  
        const endPoint = xAxis.getSeriesItem(serie, xAxis.get("end"));  
        const indexStart = serie.dataItems.indexOf(startPoint);  
        const indexEnd = serie.dataItems.indexOf(endPoint);  
        let avg = 0;  
        for (let i = indexStart; i < indexEnd; i++) {  
          avg += serie.dataItems[i].get("valueY");  
        }  

        avg = avg / (indexEnd - indexStart);  
        if (avg < 10) {  
          serie.hide();  
        } else {  
          serie.show();  
        }  

        newAverageItemsArray.push({  
          description,  
          tagName,  
          average: avg,  
          isVisible: serie.get("visible"),  
        });  
      });  

      setAverageValues({ start, end, serieAverage: newAverageItemsArray });  
    };  

    const handleStartEndChange = () => {  
      let startEndChangeTimeout;  
      if (startEndChangeTimeout) {  
        clearTimeout(startEndChangeTimeout);  
      }  
      startEndChangeTimeout = setTimeout(() => {  
        setSerieAverage();  
      }, 500);  
    };  

    mainPanel.set("cursor", XYCursor.new(root, {}));  

    let legend = chart.children.push(  
      Legend.new(root, {  
        useDefaultMarker: true,  
        layout: GridLayout.new(root, {  
          fixedWidthGrid: true,  
        }),  
      })  
    );  

    legend.data.setAll(mainPanel.series.values);  

    const stockToolbar = StockToolbar.new(root, {  
      container: document.getElementById(settings.controlid),  
      stockChart: chart,  
      controls: [  
        PeriodSelector.new(root, {  
          align: "left",  
          stockChart: chart,  
          periods: [  
            { timeUnit: "minute", count: 5, name: "5m", label: "5 minutos" },  
            { timeUnit: "minute", count: 15, name: "15m", label: "15 minutos" },  
            { timeUnit: "minute", count: 30, name: "30m", label: "30 minutos" },  
            { timeUnit: "max", name: "1h", label: "1 hora" },  
          ],  
        }),  
      ],  
    });  

    stockToolbar.get("controls")[0].events.on("periodselected", (ev) => {  
      handleStartEndChange();  
      setPeriodselected(ev.period);  
    });  

    xAxis.events.once("boundschanged", () => {  
      stockToolbar  
        .get("controls")[0]  
        .selectPeriod({ timeUnit: "minute", count: 15, label: "15 minutos" });  
    });  

    chartRef.current = mainPanel;  

    return () => root.dispose();  
  }, [data]);  

  useLayoutEffect(() => {  
    if (!updatedata || updatedata.length === 0) return;  

    const processedData = processData(updatedata);  
    const ref = chartRef.current;  
    ref.series.values.forEach((serie) => {  
      const newPoints = processedData.filter(  
        ({ measurement_column }) =>  
          measurement_column === serie._settings.userData.tag  
      );  
      const NewPointsLength = newPoints.length;  
      serie.data.pushAll(newPoints);  
      for (let i = 0; i < NewPointsLength; i++) serie.data.removeIndex(0);  
    });  
  }, [updatedata]);  

  return (  
    <ContentChart>  
      <div id={settings.controlid} className="chart-control"></div>  
      <div id={settings.chartid} style={{ height: graphHeight }}></div>  
      <ChartAverage  
        periodselected={periodselected}  
        averageValues={averageValues}  
      />  
    </ContentChart>  
  );  
};  

export default AMGraph;