import { Typography } from '@mui/material'
import React from 'react'

const TypographyAlerted = ({tagobject, children}) => {
  const status = (tagobject.value > tagobject.alarma_h_h ||tagobject.value < tagobject.alarma_l_l )? "danger": (tagobject.value > tagobject.alarma_h || tagobject.value < tagobject.alarma_l)?"warning" : "normal"
  return (
    <Typography
    sx={[
      status === "danger" && {backgroundColor: "#ac3e31",color: "#fff" },
      status === "warning" && {backgroundColor: "#dbae58"},
      ]}
    >
        {children}
    </Typography>
  )
}

export default TypographyAlerted