import useAuth from "app/hooks/useAuth";
import { flat } from "app/utils/utils";
import { Navigate, useLocation } from "react-router-dom";
import AllPages from "../routes";

const userHasPermission = (pathname, user, routes) => {
  if (!user) {
    return false;
  }

  const matched = pathname.match(`/admin/user/[0-9]`)
    ? routes.find((r) => r.path === "/admin/user/:id")
    : routes.find((r) => r.path === pathname);
  // comprueba si la ruta a navegar existe en la lista de rutas
  // console.log(matched);

  // compruebba si el usuario tiene los permisos para acceder a la ruta
  const authenticated =
    matched && matched.auth && matched.auth.length
      ? matched.auth.includes(user.tipoUsuario)
      : false;
  return authenticated;
};

const AuthGuard = ({ children }) => {
  let { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  const routes = flat(AllPages);
  const hasPermission = userHasPermission(pathname, user, routes);
  // console.log(hasPermission);

  // // IF YOU NEED ROLE BASED AUTHENTICATION,
  // // UNCOMMENT ABOVE LINES
  // // AND COMMENT OUT BELOW authenticated VARIABLE

  // let authenticated = isAuthenticated;

  return (
    <>
      {isAuthenticated ? (
        hasPermission ? (
          children
        ) : (
          <Navigate replace to="/home" state={{ from: pathname }} />
        )
      ) : (
        <Navigate replace to="/session/signin" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;
