import { Masonry } from "@mui/lab";
import React from "react";

const MasonryContainer = ({ children }) => {
  return (
    <Masonry
      columns={{ xs: 1, sm: 2, md: 2 }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      sx={{ alignContent: "center" }}
    >
      {children}
    </Masonry>
  );
};

export default MasonryContainer;
