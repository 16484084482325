import Loadable from 'app/components/Loadable';
import { lazy } from 'react';


const UsersList = Loadable(lazy(()=>import('./UsersList')));
const EditUser = Loadable(lazy(()=>import('./EditUser')));
const NewUser = Loadable(lazy(()=>import('./NewUser')));

const adminRoutes = [
    { path: "/admin/users", element: <UsersList />, auth:[1,2]},
    { path: "/admin/user/:id", element: <EditUser />, auth: [1,2,3,4,6,11,12,13,14],},
    { path: "/admin/newuser", element: <NewUser />, auth: [1,2],}
]

export default adminRoutes;