import { TableCell } from "@mui/material";

const TableCellTag = ({ tagobject, children, rowspan, colspan }) => {
  const status =
    tagobject.value >= 2 ? "started" : tagobject.value < 0 ? "lost" : "stopped";

  return (
    <TableCell
      sx={[
        status === "started" && { backgroundColor: "#6AB187" },
        status === "stopped" && { backgroundColor: "#dadada" },
        status === "lost" && { backgroundColor: "#ac3e31", color: "#fff"},
      ]}
      rowSpan={rowspan}
      colSpan={colspan}
    >
      {children}
    </TableCell>
  );
};
export default TableCellTag;
