import { StyledEngineProvider } from "@mui/styled-engine";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
// third party style
import "perfect-scrollbar/css/perfect-scrollbar.css";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StyledEngineProvider>
  </StrictMode>
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
