import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

const Snack = ({ apiMessage }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (apiMessage.resultCode !== null && apiMessage.resultCode !== "ok") {
      setOpen(true);
    }
  }, [apiMessage]);

  const handleClose = () => {
    setOpen(false);
  };

  return apiMessage.resultCode !== "" ? (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        severity={apiMessage.resultCode === "error" ? "error" : "success"}
        sx={{ width: "100%" }}
      >
        {apiMessage.statusText}
      </Alert>
    </Snackbar>
  ) : null;
};

export default Snack;
