import useSettings from 'app/hooks/useSettings';

const BrandLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (

    <svg
      version="1.0"
      width="40px"
      height="40px"
      viewBox="0 0 140.37231 140.9727"
      preserveAspectRatio="xMidYMid"
      id="svg138"
    >
      <defs
        id="defs142" />
      fill={theme.palette.primary.iconColor}
      <g
        transform="matrix(0.1,0,0,-0.1,-79,197.97271)"

        stroke="none"
        id="g136"
        fill={theme.palette.primary.iconColor}>
        <g
          id="g249"
          fill={theme.palette.primary.iconColor}>
          <path
            d="m 1449,1973 c -2,-17 2,-91 7,-145 l 6,-58 h 34 c 32,0 34,2 34,34 0,39 14,53 35,36 9,-7 12,-23 8,-46 -4,-25 -2,-34 8,-34 8,0 24,-3 36,-6 19,-5 23,-1 29,33 5,21 7,44 6,51 -2,7 4,19 13,28 18,19 20,67 3,82 -7,6 -48,15 -91,21 -92,13 -127,14 -128,4 z m 140,-59 c 8,-7 -12,-24 -30,-24 -13,0 -19,7 -19,21 0,16 4,19 23,13 12,-3 24,-8 26,-10 z"
            id="path2"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1345,1960 c -43,-9 -141,-44 -165,-60 -13,-8 -8,-23 32,-97 26,-48 51,-87 55,-86 5,2 37,13 73,25 36,12 70,23 77,24 6,2 9,12 6,24 -4,16 -11,20 -27,16 -62,-17 -96,-18 -96,-4 0,10 17,20 48,28 70,18 72,19 72,27 0,4 -4,14 -9,21 -7,11 -18,11 -67,-2 -38,-10 -62,-12 -68,-6 -16,16 5,29 69,41 33,7 62,13 63,15 6,4 -9,44 -16,43 -4,-1 -25,-5 -47,-9 z"
            id="path4"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1702,1842 c -37,-101 -38,-108 -14,-116 9,-3 22,-8 27,-11 6,-3 19,11 29,30 21,38 28,42 44,22 8,-10 6,-21 -8,-45 l -19,-31 29,-21 29,-20 25,33 c 14,19 26,38 26,43 0,5 10,14 22,19 30,13 46,58 28,80 -15,18 -135,90 -165,99 -18,5 -24,-5 -53,-82 z m 138,-22 c 0,-18 -22,-22 -37,-7 -21,21 -8,40 18,27 10,-6 19,-15 19,-20 z"
            id="path6"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1073,1838 -42,-31 67,-78 c 37,-44 69,-79 72,-79 3,1 17,11 32,23 l 28,23 -53,87 c -28,47 -54,87 -57,86 -3,0 -24,-14 -47,-31 z"
            id="path8"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 966,1739 c -14,-17 -26,-34 -26,-37 0,-4 13,-16 29,-27 24,-15 28,-23 20,-37 -12,-23 -24,-23 -58,2 l -28,19 -21,-38 c -12,-21 -22,-42 -22,-46 0,-7 164,-85 179,-85 4,0 13,12 20,27 13,28 9,35 -39,63 -13,8 -13,11 2,26 15,16 18,15 46,-4 l 29,-21 24,25 23,25 -69,69 c -38,39 -73,70 -76,70 -4,0 -19,-14 -33,-31 z"
            id="path10"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1915,1709 c -30,-28 -57,-60 -60,-70 -4,-12 6,-35 29,-66 19,-27 41,-57 49,-67 13,-19 16,-19 91,17 42,20 79,44 83,53 12,33 -91,184 -126,184 -7,0 -37,-23 -66,-51 z m 113,-70 c 3,-17 -57,-59 -84,-59 -24,0 -16,27 17,55 34,28 62,30 67,4 z"
            id="path12"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1383,1705 c -178,-48 -301,-184 -334,-366 -39,-217 106,-439 329,-504 25,-7 24,-10 47,98 11,52 21,77 31,77 17,0 17,2 3,73 -10,46 -14,55 -25,46 -8,-6 -14,-18 -14,-25 0,-8 -6,-14 -13,-14 -8,0 -23,-10 -35,-23 -17,-18 -21,-31 -16,-55 5,-26 3,-32 -10,-32 -12,0 -15,6 -10,25 8,32 -3,32 -28,0 -11,-14 -27,-25 -36,-25 -12,0 -9,7 11,28 15,16 24,32 20,36 -4,4 -15,-3 -25,-16 -14,-18 -18,-19 -18,-7 0,23 28,44 54,41 14,-2 38,14 74,51 47,48 52,56 46,86 l -7,32 -46,-45 c -49,-48 -57,-52 -81,-36 -12,8 -6,18 34,59 46,46 47,49 26,56 -18,6 -32,-2 -70,-40 -38,-38 -52,-46 -70,-40 -21,7 -20,10 26,56 40,41 46,51 34,59 -22,14 -22,14 -74,-39 -43,-44 -52,-49 -70,-39 -12,6 -23,12 -25,15 -3,2 26,35 64,73 l 69,70 h 108 c 113,0 116,1 98,45 -5,13 -3,26 8,37 10,12 16,48 20,113 3,53 8,98 12,100 4,2 10,-42 14,-98 5,-77 11,-105 23,-114 14,-11 14,-17 5,-38 -7,-14 -12,-30 -12,-35 0,-6 46,-10 108,-10 h 108 l 67,-68 c 37,-37 67,-71 67,-74 0,-4 -10,-12 -21,-19 -19,-10 -27,-5 -73,40 -37,36 -57,50 -69,45 -25,-9 -21,-17 28,-63 25,-23 43,-46 40,-51 -13,-20 -41,-8 -86,36 -37,37 -51,45 -69,39 -21,-7 -20,-9 25,-55 44,-44 52,-70 21,-70 -6,0 -32,20 -56,45 -24,25 -47,45 -51,45 -4,0 -9,-15 -12,-32 -4,-29 1,-39 47,-85 35,-36 59,-53 76,-53 29,0 50,-17 50,-39 0,-12 -5,-10 -18,7 -10,13 -21,20 -25,16 -4,-4 5,-20 20,-36 15,-15 22,-28 17,-28 -6,0 -21,11 -34,25 -25,27 -44,24 -35,-6 5,-13 2,-19 -10,-19 -13,0 -15,7 -9,35 7,40 -15,75 -47,75 -10,0 -19,9 -21,22 -6,39 -25,30 -32,-14 -4,-24 -9,-53 -13,-65 -4,-17 -1,-23 11,-23 9,0 18,-12 22,-27 3,-16 10,-44 14,-63 4,-19 11,-47 14,-62 6,-25 8,-26 43,-16 209,57 350,278 316,495 -40,262 -300,435 -550,368 z"
            id="path14"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 892,1448 c -27,-27 5,-73 38,-53 16,10 12,52 -6,59 -20,8 -18,8 -32,-6 z"
            id="path16"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 2052,1448 c -17,-17 -15,-45 4,-52 25,-10 33,-7 44,13 7,15 6,23 -6,35 -19,19 -26,20 -42,4 z"
            id="path18"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 790,1258 c 1,-85 15,-149 35,-164 13,-10 25,-10 58,0 l 42,12 v 59 c 0,57 16,92 23,50 8,-46 23,-95 30,-95 27,1 30,17 19,100 l -12,85 -47,5 c -25,3 -51,4 -57,2 -6,-2 -11,-30 -11,-68 0,-48 -4,-64 -14,-64 -14,0 -32,111 -21,130 3,6 -5,10 -19,10 -25,0 -26,-2 -26,-62 z"
            id="path20"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 2070,1265 c 0,-48 3,-55 19,-55 11,0 21,5 23,12 4,12 38,4 38,-9 -1,-29 -20,-36 -67,-27 l -48,9 -3,58 c -2,47 -6,57 -21,57 -15,0 -19,-11 -24,-67 -3,-38 -9,-76 -12,-86 -8,-19 17,-32 112,-60 75,-22 89,-8 102,98 13,106 2,125 -69,125 h -50 z"
            id="path22"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 940,1074 c -120,-45 -111,-37 -93,-84 18,-45 22,-46 63,-25 27,14 30,14 41,-6 12,-22 10,-25 -33,-50 -24,-13 -23,-19 10,-59 l 29,-34 76,65 c 41,35 76,66 76,69 0,3 -9,16 -20,29 -20,24 -21,24 -49,8 -34,-21 -46,-21 -53,-3 -4,10 6,23 28,36 31,19 32,22 22,50 -13,35 -14,35 -97,4 z"
            id="path24"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1946,1072 c -15,-28 -14,-31 7,-45 22,-16 22,-16 -9,-16 -22,-1 -36,-9 -52,-31 l -22,-29 77,-66 c 74,-63 77,-65 96,-48 39,36 41,54 11,79 l -29,23 43,1 c 41,0 44,2 63,46 12,25 19,47 17,48 -1,1 -39,15 -83,31 -44,15 -85,30 -92,32 -6,3 -19,-9 -27,-25 z"
            id="path26"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1451,915 c -13,-77 -10,-95 15,-95 11,0 14,18 14,85 0,47 -4,85 -9,85 -4,0 -14,-34 -20,-75 z"
            id="path28"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1500,905 c 0,-84 6,-98 35,-77 13,9 -12,162 -26,162 -5,0 -9,-38 -9,-85 z"
            id="path30"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1064,859 c -35,-34 -64,-68 -64,-76 0,-32 146,-139 177,-129 14,5 93,140 93,160 0,2 -32,27 -71,55 l -70,52 z m 116,-43 c 0,-15 -48,-86 -59,-86 -5,0 -14,7 -21,15 -10,12 -6,22 19,54 30,38 61,46 61,17 z"
            id="path32"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1780,869 c -30,-22 -57,-45 -59,-50 -3,-11 68,-179 76,-179 12,0 83,44 83,51 0,4 -4,11 -10,14 -16,10 -12,32 6,39 10,4 25,-1 36,-11 18,-16 20,-15 54,18 l 35,35 -70,62 c -38,34 -75,62 -83,61 -7,0 -38,-19 -68,-40 z m 83,-105 c -16,-12 -21,-10 -40,14 -43,52 -16,79 30,30 27,-28 27,-31 10,-44 z"
            id="path34"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1271,731 c -18,-44 -31,-88 -29,-98 4,-24 41,-40 126,-55 94,-16 99,-11 104,104 l 4,87 -28,6 c -39,9 -45,2 -53,-61 -9,-74 -13,-84 -35,-84 -28,0 -29,9 -10,85 l 18,70 -30,12 c -17,7 -31,13 -32,13 -1,0 -17,-36 -35,-79 z"
            id="path36"
            fill={theme.palette.primary.iconColor} />
          <path
            d="m 1615,792 c -33,-8 -70,-16 -82,-18 -22,-4 -23,-9 -23,-85 0,-93 10,-119 46,-119 44,0 162,31 174,46 14,16 6,71 -13,98 -12,16 -18,17 -55,5 -45,-13 -57,-40 -26,-57 20,-11 10,-32 -15,-32 -13,0 -21,10 -25,31 -13,67 -10,74 44,88 28,7 50,18 50,24 -1,29 -16,32 -75,19 z"
            id="path38"
            fill={theme.palette.primary.iconColor} />
        </g>
      </g>
    </svg>

  );
};

export default BrandLogo;
